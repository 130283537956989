<template>
  <div class="package-page">
    <div class="pricing-container">
      <h1 class="page-heading">Plans & Pricing</h1>
      <p class="subtitle">
        Invest in guidance that’s always there
      </p>
      <!-- Show Spinner if plans are still loading -->
      <LoadingSpinner :loading="isLoading" />

      <div class="d-flex row mt-3 w-100" v-if="!isLoading">
        <div class="pricing-plans justify-content-evenly">
          <div
            class="d-flex justify-space-between"
            v-for="plan in plans"
            :key="plan.name"
          >
            <div class="plan-card" :class="{ popular: plan.popular }">
              <div class="price">
                <!-- Show both monthly and yearly pricing -->
                <div class="price-info">
                  <span class="currency">$</span>{{ plan.amount }}
                  <span class="duration">/ {{ plan.duration }}</span>
                </div>
              </div>
              <h3>{{ plan.name }}</h3>

              <ul class="features">
                <li
                  :key="feature"
                  class="d-flex gap-2 align-items-center"
                  v-for="feature in plan.features"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                  <p class="feature mb-0">{{ feature }}</p>
                </li>
              </ul>

              <!-- Choose Plan button to open modal -->
              <button
                class="btn btn-primary my-2"
                @click="openModal(plan.id, plan.name)"
              >
                <span class="btn-text">Buy plan</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for PayPal Button -->
    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal-content">
        <button
          @click="closeModal"
          class="modal-close-btn"
          id="paypal-close-btn"
        >
          ✖
        </button>
        <h4>{{ selectedPlanName }} Plan</h4>
        <!-- PayPal Button component inside modal -->
        <PaypalDialog
          :planId="selectedPlanId"
          @show-success-dialog="handlePaypalSuccess"
          @show-error-dialog="handlePaypalError"
        />
      </div>
    </div>

    <!-- Success Dialog -->
    <DialogModal
      v-if="showSuccessDialog"
      :title="'Success'"
      :message="'Subscription successfully applied.'"
      :modalId="'successModal'"
      :button="'OK'"
      @close="closeDialog('success')"
    />

    <!-- Error Dialog -->
    <DialogModal
      v-if="showErrorDialog"
      :title="'Error'"
      :message="errorMessage"
      :modalId="'errorModal'"
      :button="'OK'"
      @close="closeDialog('error')"
    />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import PaypalDialog from "./common/PaypalDialog.vue";
import DialogModal from "./common/dialogOneBtn.vue";
import LoadingSpinner from "./common/Spinner.vue";
const plans = ref([]);
const router = useRouter();
const isLoading = ref(true);
const errorMessage = ref("");
const isModalOpen = ref(false);
const selectedPlanId = ref(null);
const showErrorDialog = ref(false);
const selectedPlanName = ref(null);
const showSuccessDialog = ref(false);

// Fetch plans from API on component mount
const fetchPlans = async () => {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_HOST}/api/paypal/get-plans`
    );
    const data = await response.json();
    if (
      !response.ok ||
      response.headers.get("content-type") !== "application/json"
    ) {
      throw new Error(`Unexpected response format: ${response.status}`);
    }

    plans.value = data.plans.map((plan) => ({
      ...plan,
      features: ["Unlimited AI Conversations: Engage in as many meaningful discussions as you need, without limits.",
"Personalized Scripture-Based Advice: Receive thoughtful, faith-centered guidance tailored to your unique questions and challenges.",
"24/7 Accessibility: Connect with your Shoulder Angel anytime, anywhere, day or night.",
"Inspiring Daily Verses: Stay uplifted with hand-picked scripture delivered directly to you.",
"Private & Secure: Enjoy safe, confidential conversations designed to strengthen your faith journey"]
    }));
  } catch (error) {
    console.error("Error fetching plans:", error);
  } finally {
    console.log("plans.value ", plans.value);

    isLoading.value = false; // Stop loading when data is fetched
  }
};

onMounted(fetchPlans);

const openModal = (planId, planName) => {
  selectedPlanId.value = planId;
  selectedPlanName.value = planName;
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const handlePaypalSuccess = (message) => {
  console.log("main success msg ", message);

  showSuccessDialog.value = true;
};

const handlePaypalError = (message) => {
  showErrorDialog.value = true;
  errorMessage.value = message;
};

const closeDialog = (dialogType) => {
  if (dialogType === "success") {
    showSuccessDialog.value = false;
    // set true
    console.log("it is close");
    // Retrieve the current user info from localStorage
    let userInfo = JSON.parse(localStorage.getItem("user")) || {};

    // Update the subscription_status to true
    userInfo.subscription_status = true;

    // Store the updated user info back in localStorage
    localStorage.setItem("user", JSON.stringify(userInfo));
    localStorage.setItem("subscription_status", true);

    // redirect chat
    const redirectTo =
      router.currentRoute.value.query.redirect || userInfo.subscription_status
        ? "/chat"
        : "/pakage";

    // Navigate to the intended route
    router.push(redirectTo);
  } else if (dialogType === "error") {
    showErrorDialog.value = false;
  }
  const paypalCloseBtn = document.getElementById("paypal-close-btn");
  paypalCloseBtn.click();
};

function setHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

window.addEventListener("resize", setHeight);
setHeight();

</script>
<style scoped lang="scss">
@import "../assets/style/main.scss";

.package-page {
  height: 100%;
  padding: 40px;
  display: flex;
  padding-top: 80px;
  align-items: center;
  justify-content: center;
  background-color: $dark-color;
  min-height: calc(var(--vh, 1vh) * 100);
  @media screen and (max-width: 576px) {
    padding: 0px 15px;
  }

  .pricing-container {
    gap: 30px;
    width: 100%;
    display: flex;
    max-width: 990px;
    padding: 40px 60px;
    text-align: center;
    border-radius: 16px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 12px $box-shadow-dark-80;
    @media screen and (max-width: 576px) {
      gap: 20px;
      width: 100%;
      padding: 22px;
      box-shadow: none;
      margin-inline: 0;
    }

    .page-heading {
      text-align: left;
      width: 100%;
    }

    p {
      &.subtitle {
        text-align: left;
        width: 100%;
      }
    }

    .pricing-plans {
      gap: 33px;
      display: flex;
      display: flex;
      --bs-gutter-x: 0;
      justify-content: flex-end;
      justify-content: center;

      @media screen and (max-width: 576px) {
        flex-direction: column;
        justify-content: center;
      }

      .plan-card {
        width: 100%;
        padding: 2rem;
        border-radius: 12px;
        margin-bottom: 2rem;
        background: $heading-color;
        box-shadow: 0 4px 10px $box-shadow-light-30;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        @media screen and (max-width: 576px) {
          margin-bottom: 0;
        }

        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 6px 20px $box-shadow-light-80;
        }

        &.popular {
          background-color: $text-color;
          color: $dark-color;

          .btn-primary {
            color: $black-color;
            background-color: $dark-color;
            border: 2px solid $black-color;
            transition: all 0.4s;
            &:hover {
              color: $dark-color;
              background-color: $black-color;
              border: 2px solid $dark-color;
            }
          }
        }

        .price {
          font-size: 3rem;
          text-align: left;
          font-weight: $bold;
          margin-bottom: 0;

          .currency {
            font-size: 1.2rem;
          }
          .duration {
            font-size: 1rem;
          }

          .price-info {
            color: $dark-color;
          }
        }

        h3 {
          font-weight: 600;
          font-size: 1.5rem;
          color: $dark-color;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1rem;
          margin-bottom: 1rem;
        }

        span {
          font-family: $primary_font;
        }

        .btn {
          width: 100%;
        }
      }
    }
    .features {
      gap: 12px;
      padding: 0;
      display: flex;
      margin-block: 30px;
      flex-direction: column;
      @media screen and (max-width: 576px) {
        margin-block: 15px;
      }

      li {
        color: $dark-color;

        p {
          color: $dark-color;
        }
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  position: relative;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
</style>
