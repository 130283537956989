<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="modalLabel">{{ title }}</h3>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            <span class="btn-text">{{ buttonOutline }}</span>
          </button>
          <router-link
            to=""
            class="link"
            @touchend="navigateToLanding"
            @mousedown="navigateToLanding"
          >
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="logoutAction"
            >
              <span class="btn-text">{{ buttonPrimary }}</span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap"; // Import Bootstrap's Modal specifically

const router = useRouter();

const navigateToLanding = () => {
  setTimeout(() => {
    router.push("/");
  }, 555);
};

// Define props for dynamic content
const props = defineProps({
  title: String,
  message: String,
  modalId: String, // Unique modal ID
  buttonPrimary: String,
  buttonOutline: String,
});

// Emit event to notify parent when modal is closed
const emit = defineEmits(["close"]);

function closeModal() {
  emit("close"); // Emit the close event to parent
}

function logoutAction() {
  localStorage.clear();
}
// Ensure Bootstrap modal works when the component is shown
onMounted(() => {
  const modalElement = document.getElementById(props.modalId);
  const bsModal = new Modal(modalElement); // Use the Modal constructor from Bootstrap
  bsModal.show();
});
</script>
<style lang="scss" scoped>
@import "../../assets/style/main.scss";

.modal-body {
  width: 88%;
  text-align: left;
}
.modal-footer {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;

  button,
  a {
    width: auto;
  }

  @media screen and (max-width: 576px) {
    gap: 0;
    justify-content: space-between;
    button {
      width: 47% !important;
    }
    a {
      width: 47% !important;
      button {
        width: 100% !important;
      }
    }
  }
}
</style>
