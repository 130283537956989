<!-- LoadingSpinner.vue -->
<template>
  <div class="d-flex justify-content-center align-items-center" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
/* Optional: Adjust spinner styles */
.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>
