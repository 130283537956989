<template>
    <nav class="top-nav">
    <div>
      <button class="btn" @click="goBack">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#1A1D2E"
          class="bi bi-arrow-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
          />
        </svg>
      </button>
    </div>
  </nav>
  <div class="about-container">
    <div class="about-card">
      <div class="logo">
        <img src="../assets/logo.png" alt="Logo" />
      </div>
      <h1 class="title page-heading">About</h1>
      <p class="details">
        Shoulder Angel is a unique web-based application designed to provide
        emotional and psychological support for users struggling with stress,
        anxiety, and depression. It acts as a virtual guide, offering comforting
        advice and helpful responses rooted in the teachings of the Bible.
        Whether users are going through a difficult time or just need someone to
        talk to, the app provides a safe and empathetic space. The Bible’s
        messages of hope, love, and perseverance are carefully integrated into
        the app’s responses, helping users find peace and clarity in moments of
        distress.
      </p>
      <p class="details">
        Shoulder Angel provides a valuable tool to foster mental well-being in a
        world where stress and emotional challenges are increasing. By
        addressing psychological concerns through the lens of faith, the app
        creates a meaningful connection for users seeking both emotional relief
        and spiritual growth.
      </p>
      <button class="btn btn-dark" @click="contactDialog">
        <span class="btn-text"> Contact Us </span>
      </button>
    </div>
    <Contact v-if="ContactDialog" :modalId="'contactModal'" @close="ContactDialog = false" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import Contact from "./common/contactDialogComp.vue"; // Import the reusable component

const ContactDialog = ref(false); // Initialize as boolean for dialog control

function goBack() {
  window.history.back();
}

function contactDialog() {
  ContactDialog.value = true;
}

function setHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', setHeight);
setHeight();
</script>

<style scoped lang="scss">
@import "../assets/style/main.scss";

.top-nav {
  width: 100vw;
  display: flex;
  position: fixed;
  padding: 20px 5%;
  background-color: $dark-color;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    padding: 15px;
  }
}

.about-container {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $heading-color;
  background: $default-bg-color;
  min-height: calc(var(--vh, 1vh) * 100);
  @media screen and (max-width: 576px) {
    padding: 0;
  }

  .about-card {
    gap: 30px;
    width: 100%;
    padding: 40px;
    display: flex;
    max-width: 555px;
    text-align: center;
    border-radius: 16px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: $dark-color;
    box-shadow: 0 4px 12px $box-shadow-light-30;
    @media screen and (max-width: 576px) {
      gap: 22px;
      width: 100%;
      border-radius: 0;
      padding: 12px 24px;
      margin-inline: auto;
      min-height: calc(var(--vh, 1vh) * 100);
    }

    .logo {
      img {
        width: 75px;
        object-fit: contain;
        @media screen and (max-width: 576px) {
          width: 55px;
        }
      }
    }

    .details {
      color: $black-color;
      text-align: justify;
    }
  }
}
</style>