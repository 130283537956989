<!-- src/components/GoogleLogin.vue -->
<template>
  <div
    id="g_id_onload"
    data-auto_prompt="false"
    data-callback="handleCredentialResponse"
    :data-client_id="clientId"
  >
    <div class="g_id_signin"></div>
    <button class="btn google-btn">
      <img
        alt="Google Logo"
        src="https://img.icons8.com/color/24/000000/google-logo.png"
      />
      <span class="btn-text" data-type="standard">Login with Google</span>
    </button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "GoogleLogin",
  data() {
    return {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID, // Dynamically set from .env file
    };
  },
  mounted() {
    // This function will be called once the Google Sign-In button is clicked
    window.handleCredentialResponse = this.handleCredentialResponse;
  },
  methods: {
    handleCredentialResponse(response) {
      const idToken = response.credential;

      // Decode the ID token to extract user data
      const base64Url = idToken.split(".")[1]; // Get the payload part of the JWT
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const decodedData = JSON.parse(atob(base64));

      const userInfo = {
        name: decodedData.name, // User's full name
        email: decodedData.email, // User's email
        googleId: decodedData.sub, // User's Google ID
        picture: decodedData.picture, // User's profile picture URL (optional)
      };

      // Store user info in localStorage
      localStorage.setItem("user", JSON.stringify(userInfo));

      // Send API request to backend for Google login authentication
      this.loginWithGoogle(userInfo);
    },

    loginWithGoogle(userInfo) {
    return axios.post(`${process.env.VUE_APP_API_HOST}/api/website/google-login`, {
      email_or_id: userInfo.email,
      user_name: userInfo.name,
      google_id: userInfo.googleId,
    })
      .then( response => {
      if (response.data.refresh_token) {
        localStorage.setItem('token', response.data.refresh_token);
      }
      this.$router.push("/chat");
    })
    .catch(error => {
      console.error("Your Password Or Email Is Wrong", error);
    });
  },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/main.scss";

div {
  &#g_id_onload {
    /* Styling for Google Sign-In button */
    .btn {
      gap: 7px;
      width: 100%;
      display: flex;
      padding: 12px;
      color: $heading-color;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      border: 3px solid $box-shadow-light-80;

      &:hover,
      &:active,
      &:focus {
        color: $heading-color;
        background: transparent;
      }

      img {
        width: 24px;
        object-fit: contain;
      }
    }
  }
}

#g_id_onload {
  padding: 0;
  overflow: hidden;
  position: relative;

  .g_id_signin {
    opacity: 0;
    z-index: 999;
    height: 100%;
    padding: 4px;
    width: min-content;
    position: absolute;
    transform: scale(1.3) translate(11%, 5%);
  }
}
</style>
