<template>
  <!-- Top Navigation Bar -->
  <nav class="top-nav">
    <div>
      <button
        class="btn toggle-sidebar"
        data-bs-toggle="offcanvas"
        data-bs-target="#sidebar"
        aria-controls="sidebar"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#1A1D2E" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
        </svg>
      </button>
      <button class="btn" @click="goBack">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#1A1D2E" class="bi bi-arrow-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
        </svg>
      </button>
    </div>
    <div class="d-flex gap-2 align-items-center">
      <template v-if="userData.picture">
        <img :src="userData.picture" class="user-pic" />
      </template>
      <template v-else>
        <div class="user-initials">
          <span>{{ getInitials(userData.name) }}</span>
        </div>
      </template>
      <p class="user-name">{{ userData.name }}</p>
    </div>
  </nav>
  <div class="set-password-container">
    <div class="set-password-card">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <h1 class="title page-heading mb-2">Account Setting</h1>
      <p>You can change your password from here</p>
      
      <form @submit.prevent="handleSubmit" class="set-pass-form">
        <div class="fields">
          <div class="group-input">
            <label for="currentPassword">Current Password</label>
            <input
              type="password"
              id="currentPassword"
              placeholder="Current Password"
              v-model="currentPassword"
              @input="validateCurrentPassword"
              :class="{ 'is-invalid': currentPasswordError }"
              required
            />
            <span v-if="currentPasswordError" class="invalid-message">{{ currentPasswordError }}</span>
          </div>

          <div class="group-input">
            <label for="password">New Password</label>
            <input
              type="password"
              id="password"
              placeholder="New Password"
              v-model="password"
              @input="validatePassword"
              :class="{ 'is-invalid': passwordError }"
              required
            />
            <span v-if="passwordError" class="invalid-message">{{ passwordError }}</span>
          </div>

          <div class="group-input">
            <label for="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Confirm Password"
              v-model="confirmPassword"
              @input="validateConfirmPassword"
              :class="{ 'is-invalid': confirmPasswordError }"
              required
            />
            <span v-if="confirmPasswordError" class="invalid-message">{{ confirmPasswordError }}</span>
          </div>
        </div>

        <button type="submit" class="btn btn-dark mt-3" :disabled="!isFormValid">
          <span class="btn-text">Update Password</span>
        </button>

        <!-- Success Dialog -->
        <DialogModal
          v-if="showSuccessDialog"
          :title="'Success'"
          :message="'Password Changed Successfully'"
          :modalId="'successModal'"
          :button="'OK'"
          @close="handleErrorDialogClose"
        />

        <!-- Error Dialog -->
        <DialogModal
          v-if="showErrorDialog"
          :title="'Error'"
          :message="'Something went wrong'"
          :modalId="'errorModal'"
          :button="'OK'"
          @close="closeSuccessDialog"
        />
      </form>
    </div>
  </div>
  <!-- Sidebar -->
  <Sidebar></Sidebar>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import Sidebar from "./common/historySidebar.vue";
import axios from "axios";
import DialogModal from "./common/dialogOneBtn.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const currentPassword = ref("");
const password = ref("");
const confirmPassword = ref("");

// Error messages
const currentPasswordError = ref(null);
const passwordError = ref(null);
const confirmPasswordError = ref(null);

// Flags for dialog display
const showSuccessDialog = ref(false);
const showErrorDialog = ref(false);

const userData = reactive({
  name: "",
  picture: "",
});

// Function to go back to previous page
function goBack() {
  window.history.back();
}

// Function to close success dialog
function closeSuccessDialog() {
  showSuccessDialog.value = false;
}

// Function to handle error dialog close and redirect
function handleErrorDialogClose() {
  showErrorDialog.value = false;
  router.push("/chat");
}

// Fetch user data on mount
onMounted(() => {
  const storedData = localStorage.getItem("user");
  if (storedData) {
    Object.assign(userData, JSON.parse(storedData));
  }
});

// Function to get user initials (first two letters)
const getInitials = (name) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  const initials = nameParts
    .map((part) => part.charAt(0).toUpperCase())
    .join("");
  return initials.slice(0, 2); // Get only the first two initials
};

// Validate fields
const validateCurrentPassword = () => {
  currentPasswordError.value = currentPassword.value ? null : "Current password is required";
};

const validatePassword = () => {
  passwordError.value = !password.value
    ? "Password is required"
    : password.value.length < 8
    ? "Password must be at least 8 characters long"
    : null;
};

const validateConfirmPassword = () => {
  confirmPasswordError.value = !confirmPassword.value
    ? "Password confirmation is required"
    : confirmPassword.value !== password.value
    ? "Passwords do not match"
    : null;
};

// Form validity check
const isFormValid = computed(() => {
  return !currentPasswordError.value && !passwordError.value && !confirmPasswordError.value;
});

// Function to refresh the page
function refresh() {
  window.location.reload();
}


// Handle form submission
const handleSubmit = async () => {
  const apiURL = `${process.env.VUE_APP_API_HOST}/api/website/change-password`;
  const storedUserInfo = localStorage.getItem("user");
  const email = storedUserInfo ? JSON.parse(storedUserInfo).email : null;

  if (!email) {
    showErrorDialog.value = true;
    return;
  }

  try {
    const response = await axios.post(apiURL, {
      email_or_id: email,
      old_password: currentPassword.value,
      new_password: password.value,
      confirm_password: confirmPassword.value,
    });

    if (response.data.error) {
      showErrorDialog.value = true;
    } else {
      showSuccessDialog.value = true;
      console.log("Password change successful:", response.data);
      refresh(); // Refresh the page
    }
  } catch (error) {
    console.error("Error:", error);
    showErrorDialog.value = true;
  }
};
</script>


<style scoped lang="scss">
@import "../assets/style/main.scss";

.top-nav {
  width: 100vw;
  display: flex;
  position: fixed;
  padding: 20px 5%;
  background-color: $dark-color;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    padding: 15px;
  }

  button {
    &.toggle-sidebar {
      border: none;
      transition: all 0.4s;
    }
  }

  .user-initials {
    width: 34px;
    height: 34px;
    padding: 8px;
    display: flex;
    font-size: 16px;
    color: $dark-color;
    border-radius: 50%;
    align-items: center;
    line-height: normal;
    font-weight: $medium;
    background: $black-color;
    justify-content: center;
  }

  .user-name {
    font-size: 17px;
    color: $heading-color;
    font-weight: $medium;
    overflow: hidden;
    display: -webkit-box; /* Needed for webkit-based browsers */
    -webkit-line-clamp: 2; /* Limits text to 4 lines */
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;

    @media screen and (max-width: 576px) {
      max-width: 188px;
    }
  }
}

.set-password-container {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $heading-color;
  background: $default-bg-color;
  padding-top: 80px;
  min-height: calc(var(--vh, 1vh) * 100);
  @media screen and (max-width: 576px) {
    padding: 0;
  }

  @media screen and (max-height: 750px) {
    padding-top: 72px;
  }

  .set-password-card {
    gap: 30px;
    width: 100%;
    padding: 40px;
    display: flex;
    max-width: 555px;
    text-align: center;
    border-radius: 16px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: $dark-color;
    box-shadow: 0 4px 12px $box-shadow-light-30;
    @media screen and (max-width: 576px) {
      gap: 22px;
      width: 100%;
      border-radius: 0;
      padding: 12px 24px;
      margin-inline: auto;
      min-height: calc(var(--vh, 1vh) * 100);
    }

    .logo {
      img {
        width: 75px;
        object-fit: contain;
        @media screen and (max-width: 576px) {
          width: 55px;
        }
      }
    }

    p {
      font-size: 17px;
      color: $text-color;
    }

    .set-pass-form {
      gap: 30px;
      width: 100%;
      display: flex;
      margin-top: -10px;
      align-items: center;
      flex-direction: column;

      .fields {
        gap: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .group-input {
          gap: 15px;
          display: flex;
          text-align: left;
          position: relative;
          flex-direction: column;

          label {
            color: $text-color;
            display: block;
          }

          input {
            width: 100%;
            padding: 8px 16px;
            font-size: 16px;
            border-radius: 12px;
            border: 3px solid $box-shadow-dark-80;
          }

          .invalid-message {
            bottom: -2px;
            font-size: 12px;
            color: $red-color;
            position: absolute;
            transform: translateY(100%);
          }
        }
      }
    }
  }
}
</style>