<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="modalLabel">Give Feedback</h3>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitFeedback">
            <div class="fields">
              <div class="group-input">
                <label for="email">Email Address</label>
                <input
                  required
                  id="email"
                  type="email"
                  v-model="email"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
              <div class="group-input">
                <label for="rating">Rating</label>
                <Star v-model="rating"></Star> <!-- Binding star rating -->
              </div>
              <div class="group-input">
                <label for="message">Message</label>
                <textarea
                  required
                  rows="3"
                  id="message"
                  v-model="message"
                  class="form-control"
                  placeholder="Your Message"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer px-0 mt-3">
              <button type="submit" class="btn btn-primary" :disabled="!isFormValid">
                <span class="btn-text">Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
   <!-- Success Modal -->
    <div
      class="modal fade"
      id="successModal"
      tabindex="-1"
      aria-labelledby="successModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="successModalLabel">Success!</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeSuccessModal"
            ></button>
          </div>
          <div class="modal-body">
            <p>Your Feedback has been successfully submitted.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="refresh">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref, computed, defineEmits, defineProps, onMounted } from "vue";
import { Modal } from "bootstrap"; // Import Bootstrap's Modal specifically
import Star from "./starRatings.vue"; // Import star ratings component
import axios from "axios"; // Import Axios for API requests

// Define props for dynamic content
const props = defineProps({
  modalId: String, // Unique modal ID
});

// State variables for feedback form
const email = ref("");
const rating = ref("0"); // Initialize rating
const message = ref("");

const isFormValid = computed(() => {
  return email.value && message.value;
});

// Emit event to notify parent when modal is closed
const emit = defineEmits(["close"]);

// Function to close the modal
function closeModal() {
  emit("close"); // Emit the close event to parent
}

// Function to refresh the page
function refresh() {
  window.location.reload();
}

// Function to handle feedback submission
async function submitFeedback() {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_HOST}/api/website/feedback`, {
      email_or_id: email.value,
      rating: rating.value,
      message: message.value,
    });

    if (response.status === 200) {
      closeModal();
      resetForm();      // Open success modal after form submission
      openSuccessModal();
    } else {
      alert("Something went wrong. Please try again.");
    }
  } catch (error) {
    console.error(error);
    alert("Failed to submit feedback. Please check your connection.");
  }
}

// Reset the form after submission
function resetForm() {
  email.value = "";
  rating.value = 0; // Reset rating
  message.value = "";
}


// Open success modal
function openSuccessModal() {
  const successModalElement = document.getElementById("successModal");
  const bsSuccessModal = new Modal(successModalElement);
  bsSuccessModal.show();
}

// Close success modal
function closeSuccessModal() {
  const successModalElement = document.getElementById("successModal");
  const bsSuccessModal = new Modal(successModalElement);
  bsSuccessModal.hide();
}


// Ensure Bootstrap modal works when the component is shown
onMounted(() => {
  const modalElement = document.getElementById(props.modalId);
  const bsModal = new Modal(modalElement); // Use the Modal constructor from Bootstrap
  bsModal.show();
});
</script>

<style lang="scss" scoped>
@import "../../assets/style/main.scss";

.modal-body {
  width: 100%;
  text-align: left;

  a{
    gap: 12px;
    align-items: center;
    text-decoration: none;

    span{
      font-size: 16px;
      color: $dark-color;
      font-weight: $medium;
    }
  }

  .fields{
    display: flex;
    flex-direction: column;
    gap: 15px;

    .group-input {
      gap: 15px;
      display: flex;
      text-align: left;
      position: relative;
      flex-direction: column;

      label {
        color: $dark-color;
        display: block;
      }

      input {
        width: 100%;
        padding: 8px 16px;
        font-size: 16px;
        border-radius: 12px;
        border: 3px solid $dark-color;

        &:focus{
          outline: none;
          box-shadow: 0 0 0 4px $primary-color;
        }
      }

      textarea {
        width: 100%;
        resize: none;
        font-size: 16px;
        padding: 8px 16px;
        border-radius: 12px;
        border: 3px solid $dark-color;

        &:focus{
          outline: none;
          box-shadow: 0 0 0 4px $primary-color;
        }
      }
    }
  }
}
</style>
