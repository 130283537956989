<template>
  <div class="set-password-container">
    <div class="set-password-card">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <h1 class="title page-heading">Set Password</h1>
      <form @submit.prevent="handleSubmit" class="set-pass-form">
        <div class="fields">
          <div class="group-input">
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              v-model="password"
              @input="validatePassword"
              :class="{ 'is-invalid': passwordError }"
              required
            />
            <span v-if="passwordError" class="invalid-message">
              {{ passwordError }}
            </span>
          </div>

          <div class="group-input">
            <label for="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              v-model="confirmPassword"
              placeholder="Confirm Password"
              @input="validateConfirmPassword"
              :class="{ 'is-invalid': confirmPasswordError }"
              required
            />
            <span v-if="confirmPasswordError" class="invalid-message">
              {{ confirmPasswordError }}
            </span>
          </div>
        </div>

        <button type="submit" class="btn btn-dark mt-3">
          <span class="btn-text">Set Password</span>
        </button>

        <!-- Success Dialog -->
          <DialogModal
            v-if="showSuccessDialog"
            :title="'Success'"
            :message="'Password has been set successfully!'"
            :modalId="'successModal'"
            :button="'OK'"
            @close="showSuccessDialog = false"
          />

          <!-- Error Dialog -->
          <DialogModal
            v-if="showErrorDialog"
            :title="'Error'"
            :message="'Some thing went wrong'"
            :modalId="'errorModal'"
            :button="'OK'"
            @close="showErrorDialog = false"
          />
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import DialogModal from "./common/dialogOneBtn.vue";
import { useRouter, useRoute } from "vue-router";

// Refs for form fields and error messages
const password = ref("");
const confirmPassword = ref("");
const passwordError = ref(null);
const confirmPasswordError = ref(null);
const showSuccessDialog = ref(false); // Success dialog state
const showErrorDialog = ref(false); // Error dialog state
// Use Vue Router to access route params and handle navigation
const router = useRouter();
const route = useRoute();

let emailOrId = "";
let otp = "";

// Extract query parameters on component mount
onMounted(() => {
  emailOrId = route.query.email_or_id || "";
  otp = route.query.otp || "";
  console.log("Email or ID:", emailOrId, "OTP:", otp);
});

// Password validation
const validatePassword = () => {
  if (!password.value) {
    passwordError.value = "Password is required";
  } else if (password.value.length < 8) {
    passwordError.value = "Password must be at least 8 characters long";
  } else {
    passwordError.value = null;
  }
};

// Confirm password validation
const validateConfirmPassword = () => {
  if (!confirmPassword.value) {
    confirmPasswordError.value = "Password is required";
  } else if (confirmPassword.value.length < 8) {
    confirmPasswordError.value = "Password must be at least 8 characters long";
  } else if (confirmPassword.value !== password.value) {
    confirmPasswordError.value = "Passwords do not match";
  } else {
    confirmPasswordError.value = null;
  }
};

// Handle form submission
const handleSubmit = async () => {
  validatePassword();
  validateConfirmPassword();

  if (passwordError.value || confirmPasswordError.value) {
    console.error("Validation failed.");
    return; // Stop if validation fails
  }

  try {
    const url = `${process.env.VUE_APP_API_HOST}/api/website/verify-otp`;
    const payload = {
      email_or_id: emailOrId,
      otp: otp,
      password: password.value,
    };

    const response = await axios.post(url, payload);

    console.log("Password Set Response:", response.data);

    if (response.data.message === "OTP verified successfully!") {
      showSuccessDialog.value = true; // Show success dialog on success
      router.push("/login"); // Redirect to login page on success
    } else {
      throw new Error("Password set failed");
    }
  } catch (error) {
    console.error("Error setting password:", error);
    showErrorDialog.value = true; // Show error dialog on failure
  }
};

function setHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', setHeight);
setHeight();
</script>

<style scoped lang="scss">
@import "../assets/style/main.scss";

.set-password-container {
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: center;
  background: $heading-color;
  background: $default-bg-color;
  min-height: calc(var(--vh, 1vh) * 100);
  @media screen and (max-width: 576px) {
    padding: 30px 0;
  }

  .set-password-card {
    gap: 30px;
    width: 100%;
    padding: 40px;
    display: flex;
    max-width: 555px;
    text-align: center;
    border-radius: 16px;
    align-items: center;
    flex-direction: column;
    background: $dark-color;
    box-shadow: 0 4px 12px $box-shadow-light-30;
    @media screen and (max-width: 576px) {
      gap: 25px;
      width: 88%;
      padding: 24px;
      margin-inline: auto;
    }

    .logo {
      img {
        width: 75px;
        object-fit: contain;
        @media screen and (max-width: 576px) {
          width: 55px;
        }
      }
    }

    .set-pass-form {
      gap: 30px;
      width: 100%;
      display: flex;
      margin-top: -10px;
      align-items: center;
      flex-direction: column;

      .fields {
        gap: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .group-input {
          gap: 15px;
          display: flex;
          text-align: left;
          position: relative;
          flex-direction: column;

          label {
            color: $text-color;
            display: block;
          }

          input {
            width: 100%;
            padding: 8px 16px;
            font-size: 16px;
            border-radius: 12px;
            border: 3px solid $box-shadow-dark-80;
          }

          .invalid-message {
            bottom: -2px;
            font-size: 12px;
            color: $red-color;
            position: absolute;
            transform: translateY(100%);
          }
        }
      }
    }
  }
}
</style>
