<template>
  <div class="code-container">
    <div class="code-card">
      <div class="logo">
        <img src="../assets/logo.png" alt="Logo" />
      </div>
      <h1 class="title page-heading">Code Verification</h1>
      <form @submit.prevent="handleSubmit" class="code-form">
        <div class="fields">
          <div class="group-input">
            <label for="code">Security Code</label>
            <input
              id="code"
              type="text"
              maxlength="6"
              v-model="code"
              class="form-control"
              @input="handleNumberInput"
              placeholder="Security Code"
              @keypress="handleNumberInput"
              :class="{ 'is-invalid': codeError }"
              :aria-invalid="codeError ? 'true' : 'false'"
            />
            <span v-if="codeError" class="invalid-message">
              Please enter a valid 6-digit security code.
            </span>
          </div>
        </div>

        <button type="submit" class="btn btn-dark mt-3">
          <span class="btn-text">Send Code</span>
        </button>
        <button type="button" class="btn btn-outline" @click="resendCode">
          <span class="btn-text">Resend Code</span>
        </button>

        <!-- Success Dialog -->
        <DialogModal
          v-if="showSuccessDialog"
          :title="'Success'"
          :message="'The code has been sent to your email!'"
          :modalId="'successModal'"
          :button="'OK'"
          @close="showSuccessDialog = false"
        />

        <!-- Error Dialog -->
        <DialogModal
          v-if="showErrorDialog"
          :title="'Error'"
          :message="'Please sign-up because this email address is not in our system'"
          :modalId="'errorModal'"
          :button="'OK'"
          @close="showErrorDialog = false"
        />
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import DialogModal from "./common/dialogOneBtn.vue";
import axios from "axios"; // Make sure axios is imported
import { useRouter } from "vue-router"; // For navigation on success

const code = ref(""); // Security code input
const codeError = ref(false); // Error state
const email_or_id = ref(""); // Email or ID input from URL
const showSuccessDialog = ref(false); // Success dialog state
const showErrorDialog = ref(false); // Error dialog state

const router = useRouter(); // Initialize router for navigation

// Extract OTP and email_or_id from the URL on component mount
onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  code.value = urlParams.get("otp") || ""; // Pre-fill the OTP if available
  email_or_id.value = urlParams.get("email_or_id") || ""; // Pre-fill email or ID if available
});

// Handle input validation to allow only digits
function handleNumberInput(evt) {
  const input = evt.target.value.replace(/\D/g, ""); // Allow only digits
  evt.target.value = input;
  code.value = input;

  codeError.value = input.length !== 6; // Set error if length is not 6
}

// Resend OTP API call
async function resendCode() {
  try {
    const url = `${process.env.VUE_APP_API_HOST}/api/website/send-otp`;
    const payload = {
      username: "adminasasasas", // Replace with dynamic value if needed
      email_or_id: email_or_id.value, // Use email or ID from URL or user input
    };

    const response = await axios.post(url, payload);

    console.log("OTP Resent:", response.data);
    showSuccessDialog.value = true; // Show success dialog on success
  } catch (error) {
    console.error("Error sending OTP:", error);
    showErrorDialog.value = true; // Show error dialog on failure
  }
}

// Handle OTP submission and verification
const handleSubmit = async () => {
  if (codeError.value) {
    console.error("Invalid Code");
    return; // Stop submission if there's an error
  }

  try {
      router.push(
        `/set-password?email_or_id=${encodeURIComponent(email_or_id.value)}&otp=${encodeURIComponent(
          code.value
        )}`
      );
  } catch (error) {
    console.error("Error verifying OTP:", error);
    showErrorDialog.value = true; // Show error dialog on failure
  }
};

function setHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', setHeight);
setHeight();
</script>

<style scoped lang="scss">
@import "../assets/style/main.scss";

.code-container {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $heading-color;
  background: $default-bg-color;
  height: calc(var(--vh, 1vh) * 100);
  @media screen and (max-width: 576px) {
    padding: 0;
  }

  .code-card {
    gap: 30px;
    width: 100%;
    padding: 40px;
    display: flex;
    max-width: 555px;
    text-align: center;
    border-radius: 16px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: $dark-color;
    box-shadow: 0 4px 12px $box-shadow-light-30;
    @media screen and (max-width: 576px) {
      gap: 25px;
      width: 88%;
      padding: 24px;
      margin-inline: auto;
    }

    .logo {
      img {
        width: 75px;
        object-fit: contain;
        @media screen and (max-width: 576px) {
          width: 55px;
        }
      }
    }

    .code-form {
      gap: 30px;
      width: 100%;
      display: flex;
      margin-top: -10px;
      align-items: center;
      flex-direction: column;

      .fields {
        gap: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .group-input {
          gap: 15px;
          display: flex;
          text-align: left;
          position: relative;
          flex-direction: column;

          label {
            color: $text-color;
            display: block;
          }

          input {
            width: 100%;
            padding: 8px 16px;
            font-size: 16px;
            border-radius: 12px;
            border: 3px solid $box-shadow-dark-80;
          }

          .invalid-message {
            bottom: -2px;
            font-size: 12px;
            color: $red-color;
            position: absolute;
            transform: translateY(100%);
          }
        }
      }
    }
  }
}
</style>