<template>
  <div class="sign-in-container">
    <div class="sign-in-card">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Profile" class="profile-img" />
      </div>
      <h1 class="title page-heading">Sign In</h1>
      <div class="social-login-buttons row">
        <!-- Include the GoogleLogin component -->
        <GoogleLogin class="col-md-6 px-2" />

        <!-- Include the FacebookLogin component -->
        <FacebookLogin class="col-md-6 px-2" />
      </div>
      <p class="or-divider">OR</p>

      <form @submit.prevent="handleSubmit" class="login-form">
        <div class="fields">
          <div class="group-input">
            <label for="email">Email Address</label>
            <input
              type="email"
              id="email"
              class="form-control"
              placeholder="Email Address"
              v-model="email_or_id"
              @input="validateEmail"
              :class="{ 'is-invalid': emailError }"
              required
            />
            <span v-if="emailError" class="invalid-message">{{
              emailError
            }}</span>
          </div>

          <div class="group-input">
            <label for="password">Password</label>
            <input
              required
              id="password"
              type="password"
              v-model="password"
              class="form-control"
              placeholder="Password"
              @input="validatePassword"
              :class="{ 'is-invalid': passwordError }"
            />
            <span v-if="passwordError" class="invalid-message">{{
              passwordError
            }}</span>
          </div>
        </div>

        <button type="submit" class="btn btn-dark">
          <span class="btn-text">Sign In</span>
        </button>

        <!-- Success Dialog -->
        <DialogModal
          v-if="showSuccessDialog"
          :title="'Success'"
          :message="'Success Login'"
          :modalId="'successModal'"
          :button="'OK'"
          @close="showSuccessDialog = false"
        />

        <!-- Error Dialog -->
        <DialogModal
          v-if="showErrorDialog"
          :title="'Error'"
          :message="'Invalid  Email or Password'"
          :modalId="'errorModal'"
          :button="'OK'"
          @close="showErrorDialog = false"
        />
      </form>

      <div class="additional-options">
        <div class="forgot-pass">
          <router-link
            to=""
            class="link"
            @touchend="navigateToForgot"
            @mousedown="navigateToForgot"
            >Forgot Password?</router-link
          >
        </div>
        <p class="join-us mt-0">
          Do not have access?
          <router-link
            to=""
            class="link"
            @touchend="navigateToSignUp"
            @mousedown="navigateToSignUp"
            >Join Us</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>



<script setup>
import { ref } from "vue";
import GoogleLogin from "@/components/GoogleLogin.vue";
import FacebookLogin from "@/components/FacebookLogin.vue";
import DialogModal from "./common/dialogOneBtn.vue"; // Import the reusable component
import axios from "axios";
import { useRouter } from "vue-router";
const showSuccessDialog = ref(false); // Initialize as boolean for dialog control
const showErrorDialog = ref(false); // Initialize as boolean for dialog control

// State variables
const email_or_id = ref("");
const password = ref("");

// Error state management
const emailError = ref(null);
const passwordError = ref(null);
const apiError = ref(null);

// API endpoint
const apiURL = `${process.env.VUE_APP_API_HOST}/api/website/login`;

// Router instance
const router = useRouter();

const navigateToSignUp = () => {
  setTimeout(() => {
    router.push("/signup");
  }, 555);
};

const navigateToForgot = () => {
  setTimeout(() => {
    router.push("/forgot-password");
  }, 555);
};

// Validation functions
const validateEmailOrId = () => {
  if (!email_or_id.value.trim()) {
    emailError.value = "Email or ID is required.";
  } else {
    emailError.value = null;
  }
};

const validatePassword = () => {
  if (!password.value.trim()) {
    passwordError.value = "Password is required.";
  } else {
    passwordError.value = null;
  }
};

// Form submission handler
const handleSubmit = async () => {
  // Validate input fields
  validateEmailOrId();
  validatePassword();

  // Stop submission if there are validation errors
  if (emailError.value || passwordError.value) return;

  try {
    // API request
    const response = await axios.post(apiURL, {
      email_or_id: email_or_id.value,
      password: password.value,
    });

    // Extract email and username from API response
    const { email, username, refresh_token, subscription_status } = response.data;

    const userInfo = {
      name: username, // User's full name or username
      email: email, // User's email
      subscription_status: subscription_status, // User's subscription status
    };
    console.log(userInfo);
    // Store user info in localStorage
    localStorage.setItem("user", JSON.stringify(userInfo));
    localStorage.setItem("token", refresh_token);
    localStorage.setItem("subscription_status", subscription_status);

    // Get the 'redirect' query parameter or default to '/chat'
    const redirectTo = router.currentRoute.value.query.redirect || subscription_status ? "/chat" : "/pakage";

    // Navigate to the intended route
    router.push(redirectTo);
  } catch (error) {
    // Handle errors from API
    apiError.value =
      error.response?.data?.message ||
      "An unexpected error occurred. Please try again.";
    showErrorDialog.value = true; // Show error message or dialog
  }
};
function setHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

window.addEventListener("resize", setHeight);
setHeight();
</script>

<style scoped lang="scss">
@import "../assets/style/main.scss";

.sign-in-container {
  width: 100%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $heading-color;
  background: $default-bg-color;
  min-height: calc(var(--vh, 1vh) * 100);
  @media screen and (max-width: 576px) {
    padding: 0;
  }
}

.sign-in-card {
  gap: 30px;
  width: 100%;
  display: flex;
  max-width: 620px;
  padding: 40px 60px;
  text-align: center;
  border-radius: 16px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: $dark-color;
  box-shadow: 0 4px 12px $box-shadow-light-30;
  @media screen and (max-width: 576px) {
    gap: 22px;
    width: 100%;
    border-radius: 0;
    padding: 12px 24px;
    margin-inline: auto;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .logo {
    img {
      width: 75px;
      object-fit: contain;
      @media screen and (max-width: 576px) {
        width: 55px;
      }
    }
  }

  .social-login-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 576px) {
      gap: 15px;
      flex-direction: column;
    }
  }

  .or-divider {
    color: $text-color;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .login-form {
    gap: 30px;
    width: 100%;
    display: flex;
    margin-top: -10px;
    align-items: center;
    flex-direction: column;

    .fields {
      gap: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .group-input {
        gap: 15px;
        display: flex;
        text-align: left;
        position: relative;
        flex-direction: column;

        label {
          color: $text-color;
          display: block;
        }

        input {
          width: 100%;
          padding: 8px 16px;
          font-size: 16px;
          border-radius: 12px;
          border: 3px solid $box-shadow-light-80;

          &:focus {
            outline: none;
            box-shadow: 0 0 0 4px $primary-color;
          }
        }

        .invalid-message {
          bottom: -2px;
          font-size: 12px;
          color: $red-color;
          position: absolute;
          transform: translateY(100%);
        }
      }
    }
  }

  .additional-options {
    width: 100%;
    gap: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 576px) {
      gap: 12px;
    }

    .forgot-pass {
      width: 100%;
      font-size: 1rem;
      text-align: right;
    }

    .join-us {
      color: $heading-color;
      font-size: 1rem;
    }

    .link {
      position: relative;
      text-decoration: none;
      color: $heading-color;
      font-weight: $semi_bold;
      &:active,
      &:focus {
        background-blend-mode: darken;
      }
      &::after {
        content: "";
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        position: absolute;
        transform: scaleX(0);
        transform-origin: bottom right;
        background-color: $heading_color;
        transition: transform 0.2s ease-out;
      }

      &:hover::after,
      &.active::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}
</style>
