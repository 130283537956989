<template>
  <div id="app">
    <router-view></router-view>
    <!-- This is where the routed components will be rendered -->
  </div>
</template>

<script setup>
</script>

<style lang="scss">
@import "./assets/style/main.scss";

body {
  margin: 0;
  #app {
    margin: 0px;
    color: #2c3e50;
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  .btn {
    &:hover,
    &:active,
    &:focus {
      color: inherit;
      background-color: inherit;
      border-color: inherit;
    }
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    color: unset;
    background-color: unset;
    border-color: unset;
  }
}
</style>
