<template>
  <div class="new-chat-container">
    <!-- Main Content -->
    <main class="main-content">
      <!-- Top Navigation Bar -->
      <nav class="top-nav">
        <div class="gap-3 navbar-box">
          <img
            src="@/assets/logo.png"
            alt="Welcome Image"
            class="welcome-img nav-logo"
          />
          <router-link
            to=""
            class="link"
            @touchend="navigateToLogin"
            @mousedown="navigateToLogin">
            <button class="btn btn-dark">
              <span class="btn-text"> Sign In </span>
            </button>
          </router-link>
        </div>
      </nav>

      <div class="main-section trail-version gap-5">
        <!-- Welcome Section -->
        <div class="welcome-section" v-if="!isQuestionSent">
          <img
            src="@/assets/logo.png"
            alt="Welcome Image"
            class="welcome-img"
          />
          <h1 class="my-3">Unlock your spirit guide</h1>
          <p class="welcome-text">
            I am here to listen, guide, and support you through scripture. How
            can we help you today?
          </p>
          <div class="d-flex gap-3 align-items-center mt-4">
            <router-link
              to=""
              class="link"
              @touchend="navigateToAbout"
              @mousedown="navigateToAbout">
              <button class="btn btn-dark">
                <span class="btn-text"> About Us </span>
              </button>
            </router-link>
          </div>
        </div>
        <div class="chat-section trail-version" ref="chatSection">
          <div
            class="question-user"
            v-for="(item, index) in questions"
            :key="index"
          >
            <p
              id="question"
              class="question"
              v-html="formatText(item.question)"
            ></p>
            <p id="response-{{ index }}" class="response">
              <span
                v-for="(char, charIndex) in item.typedResponse"
                :key="charIndex"
                v-html="formatText(char)"
              ></span>
            </p>
            <div class="hint" v-show="isHintVisible" ref="hint">
              <h5>
                To see the full response and receive even more personalized
                Bible-based advice, upgrade now and enjoy
              </h5>
              <br />
              <ul class="features">
                <li class="d-flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    class="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                  <p class="feature mb-0">
                    <strong>Unlimited Conversations:</strong> get all the
                    spiritual support you need, whenever you need it;
                  </p>
                </li>
                <li class="d-flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    class="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                  <p class="feature mb-0">
                    <strong>Faster Responses:</strong> Enjoy instant,
                    compassionate guidance without waiting.
                  </p>
                </li>
                <li class="d-flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    class="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                  <p class="feature mb-0">
                    <strong>Enhanced Accuracy:</strong> More precise and
                    insightful responses tailored just for you.
                  </p>
                </li>
                <li class="d-flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    class="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                  <p class="feature mb-0">
                    <strong>Personalized Daily Verses:</strong> Receive
                    scriptures that align with your unique spiritual journey.
                  </p>
                </li>
              </ul>
              <router-link
                to=""
                class="w-100 link"
                @touchend="navigateToLogin"
                @mousedown="navigateToLogin">
                <button class="btn btn-primary mt-3">
                  <span class="btn-text"> Unlock Full Access Now </span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Chat Input -->
      <v-layout class="input-group row w-100 gap-3">
        <v-flex class="text-box">
          <textarea
            rows="1"
            ref="autoTextarea"
            style="height: 50px"
            v-model="newQuestion"
            :disabled="newQuestionDisabled"
            placeholder="How can I help you?"
          ></textarea>
        </v-flex>
        <v-flex class="button-box">
          <button
            class="send-btn"
            @click="sendQuestion"
            :disabled="sendButtonDisabled"
          >
            <svg
              width="20"
              height="20"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-send-fill"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"
              />
            </svg>
          </button>
        </v-flex>
      </v-layout>
    </main>
  </div>
</template>

<script setup>
import axios from "axios";
import { useRouter } from 'vue-router';
import { ref, onMounted, nextTick } from "vue";

// Reactive data
const questions = ref([]);
const newQuestion = ref("");
const chatSection = ref(null);
const autoTextarea = ref(null);
const isHintVisible = ref(false);
const isQuestionSent = ref(false);
const sendButtonDisabled = ref(false);
const newQuestionDisabled = ref(false);

const router = useRouter();

const navigateToLogin = () => {
  setTimeout(() => {
    router.push("/login");
  }, 555);
};

const navigateToAbout = () => {
  setTimeout(() => {
    router.push("/about");
  }, 555);
};

// Function to simulate typing effect
const typeWriterEffect = (responseText, index) => {
  questions.value[index].typedResponse = [];
  let i = 0;
  const interval = setInterval(() => {
    if (i < responseText.length) {
      questions.value[index].typedResponse.push(responseText[i]);
      i++;
      // Scroll to bottom on each character
      scrollToBottom();
    } else {
      clearInterval(interval);
      // Enable the send button once typing is done
      scrollToBottom(); // Ensure final scroll
      hintDisplay();
    }
  }, 17);
};

// Function to send a question
const sendQuestion = async () => {
  if (newQuestion.value.trim() !== "") {
    // Create a question object
    const questionObj = {
      question: newQuestion.value.trim(),
      typedResponse: [],
    };

    // Add the new question to the list
    questions.value.push(questionObj);

    // Prepare API input
    const apiInput = {
      user_input: newQuestion.value,
      conversation_history: questions.value.map(item => ({
        ai: item.response || '',
        user: item.question,
      })),
    };

    try {
      // Reset textarea height immediately after sending the message
      resetTextareaHeight();
      // Send request to the Flask API
      const response = await axios.post(`${process.env.VUE_APP_GPT_HOST}/generate_response`, apiInput);

      // Handle API response
      if (response.data && response.data.conversation_history) {
        const latestResponse = response.data.conversation_history[response.data.conversation_history.length - 1];
        questionObj.response = latestResponse.ai;  // Set AI response for the latest question
        typeWriterEffect(latestResponse.ai, questions.value.length - 1);

        // Check if it's in the trail version (if applicable)
        const chatSection = document.querySelector(".chat-section");
        if (chatSection && chatSection.classList.contains("trail-version")) {
          sendButtonDisabled.value = true;
          newQuestionDisabled.value = true;
        }
      }
    } catch (error) {
      console.error("Error fetching response from API:", error);
      // Optionally, you can handle the error state
    } finally {
      newQuestion.value = "";  // Clear the input after sending
    }
  }
};

const hintDisplay = () => {
  const chatSection = document.querySelector(".chat-section");
  if (chatSection && chatSection.classList.contains("trail-version")) {
    setTimeout(() => {
      isHintVisible.value = true;
      scrollToBottom();
    }, 1500);
  }
};
// Function to reset the textarea height
const resetTextareaHeight = () => {
  if (autoTextarea.value) {
    autoTextarea.value.style.height = "50px"; // Set the height to default (50px)
  }

  // Scroll to the bottom after adding the new question
  scrollToBottom();
};

// Adjust textarea height
const adjustHeight = (event) => {
  const textarea = event.target;
  textarea.style.height = "auto";
  const lineHeight = parseFloat(window.getComputedStyle(textarea).lineHeight);
  const maxHeight = lineHeight * 4;
  if (textarea.scrollHeight > maxHeight) {
    textarea.style.height = `${maxHeight}px`;
    textarea.style.overflowY = "scroll";
  } else {
    textarea.style.height = `${textarea.scrollHeight}px`;
    textarea.style.overflowY = "hidden";
  }
};

// Scroll to bottom function
const scrollToBottom = () => {
  nextTick(() => {
    if (chatSection.value) {
      chatSection.value.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  });
};

// Format text by replacing new lines with <br>
const formatText = (text) => text.replace(/\n/g, "<br>");

// Event listener for adjusting textarea height
onMounted(() => {
  if (autoTextarea.value) {
    autoTextarea.value.addEventListener("input", adjustHeight);
  }
});

function setHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', setHeight);
setHeight();
</script>

<style scoped lang="scss">
@import "../assets/style/main.scss";

.new-chat-container {
  display: flex;
  height: calc(var(--vh, 1vh) * 100);

  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $dark-color;

    .top-nav {
      display: flex;
      padding: 20px 5%;
      background-color: $dark-color;
      justify-content: space-between;

      @media screen and (max-width: 576px) {
        padding: 15px;
      }

      .navbar-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav-logo {
          width: 48px;
        }
      }
    }

    .main-section {
      display: flex;
      flex-direction: column;
      height: calc(100% - 176px);
      padding: 20px 40px;
      overflow-y: auto;
      @media screen and (max-width: 576px) {
        padding: 20px;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 7px;
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $white-color;
        border-radius: 7px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $light-color;
      }

      .welcome-section {
        min-height: 300px;
        width: 88%;
        margin-inline: auto;
        flex-grow: 1;
        display: flex;
        text-align: center;
        align-items: baseline;
        flex-direction: column;
        justify-content: center;

        .welcome-img {
          width: 75px;
        }

        h1 {
          margin-bottom: 7px;
        }

        .link {
          color: $black-color;
          text-decoration: none;
        }

        .welcome-text {
          margin-left: 0;
          max-width: 600px;
          color: $text-color;
        }
      }

      .chat-section {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .question-user {
          gap: 25px;
          display: flex;
          padding-inline: 33px;
          flex-direction: column;
          @media screen and (max-width: 576px) {
            padding-inline: 0;
          }

          .question {
            max-width: 55vw;
            text-align: right;
            color: $dark-color;
            width: max-content;
            padding: 15px 30px;
            position: relative;
            background: $heading-color;
            align-self: flex-end;
            overflow-wrap: anywhere;
            border-radius: 24px 24px 0 24px;
            @media screen and (max-width: 576px) {
              max-width: 82vw;
            }
          }

          .response {
            width: auto;
            max-width: 77vw;
            text-align: left;
            overflow: hidden;
            position: relative;
            padding: 15px 30px;
            color: $text-color;
            font-weight: $medium;
            align-self: flex-start;
            overflow-wrap: anywhere;
            background: $light-color;
            border-radius: 24px 24px 24px 0;

            @media screen and (max-width: 576px) {
              max-width: 82vw;
            }

            &::after {
              content: "";
              width: 100%;
              bottom: 0;
              left: 0;
              z-index: 9999;
              position: absolute;
              height: calc(100% - 55px);
              background-color: $box-shadow-light-80;
              backdrop-filter: blur(7px) saturate(177%);
              -webkit-backdrop-filter: blur(7px) saturate(177%);
              -webkit-box-shadow: 2px -5px 15px 0px $box-shadow-light-80;
              -moz-box-shadow: 2px -5px 15px 0px $box-shadow-light-80;
              box-shadow: 2px -5px 15px 0px $box-shadow-light-80;
            }
          }
        }

        .hint {
          margin-block: 10px;
          padding-block: 25px;
          border-radius: 12px;
          background: $heading-color;
          padding-inline: 12px 24px;
          border: 2px dashed $dark-color;

          h5 {
            color: $white-color;
            text-align: center;
          }

          .features {
            display: flex;
            gap: 7px;
            flex-direction: column;

            li {
              p {
                font-size: 17px;
                color: $white-color;
              }
            }
          }
        }
      }
    }

    .input-group {
      display: flex;
      --bs-gutter-x: 0;
      padding: 20px 6%;
      align-items: center;
      justify-content: center;
      background-color: $dark-color;
      @media screen and (max-width: 576px) {
        padding: 20px;
      }

      .text-box {
        width: calc(100% - 48px - 16px);
      }

      .button-box {
        width: auto;
      }

      textarea {
        width: 100%;
        resize: none; /* Prevent manual resizing by user */
        max-height: 6em; /* Max height for 4 lines (1.5em per line is a typical size) */
        font-size: 16px;
        overflow-y: auto; /* Adds scroll when max height is reached */
        line-height: 1.5em;
        padding-block: 12px;
        box-sizing: border-box;
        background: $white-color;
        border: 2px solid $light-color;

        /* width */
        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: none;
          border-radius: 7px;
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 7px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $black-color;
        }
      }

      .send-btn {
        width: 48px;
        height: 48px;
        border: none;
        display: flex;
        margin-bottom: 8px;
        color: $dark-color;
        border-radius: 50%;
        align-items: center;
        transform: all 0.4s;
        justify-content: center;
        background-color: $black-color;

        svg {
          transform: translate(-1px, 1px);
        }

        &:disabled {
          cursor: not-allowed;
          background: $disabled-color;
        }

        &:active {
          background: $heading-color;
          transform: scale(0.92)
        }
      }
    }
  }
}
</style>
