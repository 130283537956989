// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import landingPage from "../components/landingPageComp.vue";
import loginComp from "../components/loginComp.vue";
import signupComp from "../components/signupComp.vue";
import setPass from "../components/setPassComp.vue";
import resetPass from "../components/resetPassComp.vue";
import forgotPass from "../components/forgotPassComp.vue";
import code from "../components/codeComp.vue";
import chat from "../components/chatComp.vue";
import Package from "../components/packageComp.vue";
import about from "../components/aboutComp.vue";
import account from "../components/accountComp.vue";

// Simulated authentication state (replace with Vuex or actual logic)
function isAuthenticated() {
  // Replace with real authentication check (e.g., JWT in localStorage)
  return localStorage.getItem("token") !== null;
}

function setUserRolePage( redirect ) {
  if ( isSubscribe() ) {
    if ( redirect ) {
      return redirect == "/package" ? "/chat" : redirect;
    }
    else {
      return "/chat";
    }
  }
  else {
    return "/package";
  }
}

// Simulated authentication state (replace with Vuex or actual logic)
function isSubscribe() {
  // Replace with real authentication check (e.g., JWT in localStorage)
  return localStorage.getItem("subscription_status") == "true";
}

const routes = [
  { path: "/", name: "landing page", component: landingPage },
  { path: "/login", name: "login", component: loginComp },
  { path: "/signup", name: "signup", component: signupComp },
  { path: "/set-password", name: "set Password", component: setPass },
  { path: "/reset-password", name: "reset Password", component: resetPass },
  { path: "/forgot-password", name: "forgot Password", component: forgotPass },
  { path: "/code", name: "security code", component: code },
  { path: "/chat", name: "chat", component: chat, meta: { requiresAuth: true } },
  { path: "/package", name: "Package", component: Package, meta: { requiresAuth: true } },
  { path: "/about", name: "about", component: about },
  { path: "/account", name: "account", component: account, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add navigation guard for handling non-existing routes
router.beforeEach((to, from, next) => {
  const isLoggedIn = isAuthenticated(); // Replace with your actual authentication check
  const isSub = isSubscribe(); // Replace with your actual subscription check

  // Handle google-login /login route with forceReload logic
  if (to.path === "/login" && to.query.forceReload !== "true") {
    // Add forceReload=true in the query and replace the route
    next({
      path: to.path,
      query: { ...to.query, forceReload: "true" },
      replace: true,
    });

    // Trigger reload only for the login page
    if (to.path === "/login" && from.path !== "/login") {
      setTimeout(() => {
        window.location.reload(); // Reload the page after route update
      }, 0);
    }
    return; // Prevent further execution
  }

  // If the user is logged in and the target route does NOT require authentication
  if (isLoggedIn && !to.matched.some(record => record.meta.requiresAuth)) {
    // Redirect the logged-in user to a default authenticated page (e.g., /chat)
    const redirectTo = to.query.redirect || (isSub ? "/chat" : "/package");
    // Avoid redirecting to the same route (infinite loop prevention)
    if (to.path !== redirectTo) {
      next({ path: redirectTo }); // Redirect to chat or package
    } else {
      next(); // Do nothing if already on the correct route
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    // If the route requires authentication
    if (!isLoggedIn) {
      // Not authenticated, redirect to login page with the intended route as a query
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      // Authenticated, determine the appropriate page based on user role/subscription
      const redirectTo = setUserRolePage(to.path);
      // Avoid redirecting to the same route (infinite loop prevention)
      if (to.path !== redirectTo) {
        next({ path: redirectTo }); // Redirect to chat or package
      } else {
        next(); // Do nothing if already on the correct route
      }
    }
  } else {
    // Route does not require authentication, proceed as normal
    next();
  }
});

export default router;
