<template>
  <div ref="paypalButtonContainer"></div>
</template>

<script>
/* global paypal */

const apiURL = `${process.env.VUE_APP_API_HOST}/api/website/subscription-active`;
export default {
  name: "PaypalDialog",
  props: {
    planId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: "", // Make email part of the component's state
    };
  },
  mounted() {
    // Load user email from localStorage during component mount
    const storedUserInfo = localStorage.getItem("user");
    if (storedUserInfo) {
      try {
        const userInfo = JSON.parse(storedUserInfo);
        this.email = userInfo.email || "";
      } catch (error) {
        console.error("Error parsing localStorage user data:", error);
      }
    } else {
      console.log("No user info found in localStorage.");
    }

    // Load the PayPal SDK script dynamically if not already loaded
    if (!document.getElementById("paypal-sdk")) {
      const script = document.createElement("script"); // Fixed missing script creation
      script.id = "paypal-sdk";
      script.setAttribute("data-sdk-integration-source", "button-factory");
      script.onload = () => {
        this.renderPaypalButton();
      };
      document.head.appendChild(script);
    } else {
      this.renderPaypalButton();
    }
  },
  methods: {
    renderPaypalButton() {
      if (typeof paypal !== "undefined") {
        paypal.Buttons({
          style: {
            shape: 'rect',
            color: 'gold',
            layout: 'vertical',
            label: 'subscribe',
          },
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: this.planId,
              application_context: {
                shipping_preference: "NO_SHIPPING"
              }
            });
          },
          onApprove: ( data ) => {
            // Optionally, call a webhook directly from your app
            console.log('paypal dialog success before calling webhook', data);
            if ( this.email ) {
              data.email = this.email;
              this.sendReqToActiveStatus(data);
            }
            
            this.$emit('show-success-dialog', 'Subscription successful! Please check your email or ID for the reset code.');
          },
          onError: (err) => {
            console.log('paypal dialog err ', err, JSON.parse(err));
            this.$emit('show-error-dialog', 'An error occurred with PayPal. Please try again.');
          },
        }).render(this.$refs.paypalButtonContainer);
      }
    },
    sendReqToActiveStatus(data) {
      console.log('yes i am here with webhook data ', data );
      fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          subscriptionId: data.subscriptionID,
          email_or_id: this.email, // Use email from component state
        })
      })
      .then(response => response.json())
      .then(data => console.log("Webhook sent:", data))
      .catch(error => console.error("Error sending webhook:", error));
    }
  },
};
</script>

<style scoped>
/* Optional styles for the PayPal button container */
</style>
