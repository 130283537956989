<template>
  <!-- Sidebar -->
  <div
    id="sidebar"
    tabindex="-1"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    class="offcanvas offcanvas-start sidebar"
    aria-labelledby="offcanvasScrollingLabel"
  >
    <div class="offcanvas-header">
      <div class="d-flex gap-3 w-100">
        <!-- <div class="logo">
          <img alt="Logo" width="55" src="@/assets/logo.png" />
        </div> -->
        <button
          type="button"
          aria-label="Close"
          class="btn-close"
          data-bs-dismiss="offcanvas"
        ></button>
      </div>
      <button class="btn btn-dark w-100" @click="startNewChat" >
        <span class="btn-text">+ &nbsp; New Chat</span>
      </button>
    </div>
    <div class="offcanvas-body">
      <!-- Chat List -->
     <div class="chat-list">
  <div
    :key="index"
    role="button"
    class="chat-item"
    :title="chat.preview"
    v-for="(chat, index) in chats"
    @click="navigateToChat(chat.conversation_id)"
  >
    <p class="chat-date">{{ chat.date }}</p>
    <p class="chat-preview">{{ chat.preview }}</p>
  </div>
  <!-- This section allows navigation to the chat based on conversation_id -->
</div>

    </div>
    <div class="offcanvas-footer">
      <button class="btn sidebar-footer-btn" @click="clearConversationHistory">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-trash3-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"
          />
        </svg>
        <span class="btn-text">Clear Conversation History</span>
      </button>
      <router-link
        to=""
        class="link"
        @touchend="navigateToAccounts"
        @mousedown="navigateToAccounts">
        <button class="btn sidebar-footer-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-gear-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"
            />
          </svg>
          <span class="btn-text">Account Settings</span>
        </button>
      </router-link>
      <button class="btn sidebar-footer-btn" @click="feedbackDialog">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chat-right-dots-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353zM5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 1 0-2 1 1 0 0 1 0 2"
          />
        </svg>
        <span class="btn-text">Give Feedback</span>
      </button>
      <button class="btn sidebar-footer-btn" @click="logoutDialog">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-door-open-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15zM11 2h.5a.5.5 0 0 1 .5.5V15h-1zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1"
          />
        </svg>
        <span class="btn-text">Log out</span>
      </button>
    </div>
  </div>
  <!-- Logout Dialog -->
  <DialogModal
    v-if="LogoutDialog"
    :title="'Confirmation'"
    :message="'Are you sure you want to logout?'"
    :modalId="'logoutModal'"
    :buttonPrimary="'Yes'"
    :buttonOutline="'No'"
    @close="LogoutDialog = false"
  />
  <Feedback
    v-if="FeedbackDialog"
    :modalId="'feedbackModal'"
    @close="FeedbackDialog = false"
  />
</template>

<script setup>
import DialogModal from "./logoutDialog.vue"; // Import the reusable component
import Feedback from "./feedbackDialogComp.vue"; // Import the reusable component
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router'; // Import useRouter
// Store user email
const storedUserInfo = localStorage.getItem("user");
let email = "";
const router = useRouter();

const navigateToAccounts = () => {
  setTimeout(() => {
    router.push("/account");
  }, 555);
};

if (storedUserInfo) {
  const userInfo = JSON.parse(storedUserInfo);
  email = userInfo.email;
}
const chats = ref([]); // Initialize chats array

const fetchConversationMessages = async () => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/api/website/chat-list`,
      { params: { email_or_id: email } }
    );

    if (response.data && response.data.chats) {
      chats.value = response.data.chats.map(chat => ({
        date: chat.date,
        preview: chat.preview,
        conversation_id: chat.conversation_id
      }));
    }
  } catch (error) {
    console.error('Error fetching conversation messages:', error);
  }
};


const clearConversationHistory = async () => {
  try {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/api/website/delete-chats`,
      {
        params: { email_or_id: email },
      }
    );

    if (response.status === 200) {
      console.log('Conversation history cleared successfully.');
      // Refresh the page immediately after deletion
      window.location.reload();
    } else {
      console.warn('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Error clearing conversation history:', error);
    alert('Failed to clear conversation history. Please try again.');
  }
};
onMounted(() => {
  fetchConversationMessages(); // Fetch messages when the component mounts
});

// Function to navigate to the chat URL and refresh the page
function navigateToChat(conversationId) {
  router.push({ path: `/chat`, query: { conversation_id: conversationId } })
    .then(() => {
      // Use setTimeout to allow the router to change the path before refreshing
      setTimeout(() => {
        window.location.reload();
      }, 0);
    });
}

const LogoutDialog = ref(false); // Initialize as boolean for dialog control
const FeedbackDialog = ref(false); // Initialize as boolean for dialog control

// Function to trigger the logout dialog
function logoutDialog() {
  LogoutDialog.value = true;
}

function feedbackDialog() {
  FeedbackDialog.value = true;
}

// Function to navigate to the /chat route and refresh the page state
function startNewChat() {
  window.location.href = '/chat'; // Navigates to the route and reloads the page
}


</script>

<style lang="scss" scoped>
@import "../../assets/style/main.scss";

.sidebar {
  background-color: $dark-color;
  color: $black-color;
  max-width: 333px;

  .offcanvas-header {
    gap: 15px;
    flex-direction: column;
    border-bottom: 2px solid $light-color;

    button {
      &.btn-close {
        opacity: 1;
        filter: invert(1);
      }
    }
  }

  .offcanvas-body {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 0;
    margin-bottom: 16px;

    .chat-list {
      .chat-item {
        gap: 2px;
        display: flex;
        padding: 12px 4px;
        position: relative;
        transition: all 0.3s;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 2px solid $light-color;
        &:hover {
          p {
            color: $dark-color;
            z-index: 99;
          }
        }

        .chat-preview {
          overflow: hidden;
          display: -webkit-box; /* Needed for webkit-based browsers */
          -webkit-line-clamp: 2; /* Limits text to 4 lines */
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }

        &::after {
          content: "";
          top: 0;
          bottom: 0;
          left: -4%;
          width: 107%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          background: $black-color;
          transform: scaleY(0);
          transform-origin: top;
          transition: transform 0.3s ease-in;
        }

        &:hover::after,
        &.active::after {
          border-radius: 8px;
          transform: scaleY(1);
          transform-origin: bottom;
        }
      }

      & > :last-child {
        border-bottom: none;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 7px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $black-color;
      border-radius: 7px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $light-color;
    }
  }

  .offcanvas-footer {
    gap: 6px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 2px solid $light-color;

    .sidebar-footer-btn {
      gap: 5px;
      display: flex;
      color: $black-color;
      align-items: center;
      background: transparent;

      &:hover, &:active {
        border: 1px solid $heading_color;
      }
    }
  }

  .link {
    text-decoration: none;
  }
}
</style>