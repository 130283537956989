<template>
  <div class="forgot-password-container">
    <div class="forgot-password-card">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <h1 class="title page-heading">Forgot Password</h1>

      <form @submit.prevent="submitForm" class="forgot-pass-form">
        <div class="fields">
          <div class="group-input">
            <label for="email">Email Address or User ID</label>
            <input
              type="text"
              id="email"
              class="form-control"
              placeholder="Enter Email or ID"
              v-model="email"
              @input="validateEmail"
              :class="{ 'is-invalid': emailError }"
              required
            />
            <span v-if="emailError" class="invalid-message">{{ emailError }}</span>
          </div>
        </div>

        <button type="submit" class="btn btn-dark mt-3">
          <span class="btn-text">Send Code</span>
        </button>

        <!-- Success Dialog -->
        <DialogModal
          v-if="showSuccessDialog"
          :title="'Success'"
          :message="'Please check your email or ID for the reset code!'"
          :modalId="'successModal'"
          :button="'OK'"
          @close="closeDialog('success')"
        />

        <!-- Error Dialog -->
        <DialogModal
          v-if="showErrorDialog"
          :title="'Error'"
          :message="errorMessage"
          :modalId="'errorModal'"
          :button="'OK'"
          @close="closeDialog('error')"
        />
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import DialogModal from "./common/dialogOneBtn.vue";

// State management
const email = ref("");
const emailError = ref(null);
const showSuccessDialog = ref(false);
const showErrorDialog = ref(false);
const errorMessage = ref("");

// Validate email or ID input
const validateEmail = () => {
  if (!email.value) {
    emailError.value = "Email or ID is required";
  } else if (!email.value.includes("@") && !email.value.match(/^\d+$/)) {
    emailError.value = "Invalid input. Enter a valid email or numeric ID";
  } else {
    emailError.value = null;
  }
};
// Close dialog based on type
const closeDialog = (type) => {
  if (type === "success") showSuccessDialog.value = false;
  if (type === "error") showErrorDialog.value = false;
};

// Handle form submission
const submitForm = async () => {
  if (emailError.value) return;

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/api/website/forgot-password/`,
      { email_or_id: email.value }
    );

    if (response.status === 200) {
      showSuccessDialog.value = true;
      resetForm();
    }
  } catch (error) {
    errorMessage.value =
      error.response?.data?.error || "Something went wrong. Please try again.";
    showErrorDialog.value = true;
  }
};

// Reset form inputs
const resetForm = () => {
  email.value = "";
  emailError.value = null;
};

// Set viewport height
function setHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', setHeight);
setHeight();
</script>


<style scoped lang="scss">
@import "../assets/style/main.scss";

.forgot-password-container {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $heading-color;
  background: $default-bg-color;
  height: calc(var(--vh, 1vh) * 100);
  @media screen and (max-width: 576px) {
    padding: 30px 0;
  }

  .forgot-password-card {
    gap: 30px;
    width: 100%;
    padding: 40px;
    display: flex;
    max-width: 555px;
    text-align: center;
    border-radius: 16px;
    align-items: center;
    flex-direction: column;
    background: $dark-color;
    box-shadow: 0 4px 12px $box-shadow-light-30;
    @media screen and (max-width: 576px) {
      gap: 30px 20px;
      width: 88%;
      padding: 22px;
      margin-inline: auto;
    }

    .logo {
      img {
        width: 75px;
        object-fit: contain;
      }
    }

    .forgot-pass-form {
      gap: 30px;
      width: 100%;
      display: flex;
      margin-top: -10px;
      align-items: center;
      flex-direction: column;

      .fields {
        gap: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .group-input {
          gap: 15px;
          display: flex;
          text-align: left;
          position: relative;
          flex-direction: column;

          label {
            color: $text-color;
            display: block;
          }

          input {
            width: 100%;
            padding: 8px 16px;
            font-size: 16px;
            border-radius: 12px;
            border: 3px solid box-shadow-dark-80;
          }

          .invalid-message {
            bottom: -2px;
            font-size: 12px;
            color: $red-color;
            position: absolute;
            transform: translateY(100%);
          }
        }
      }
    }
  }
}
</style>
