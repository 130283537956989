<template>
  <div class="signup-container">
    <div class="signup-card">
      <div class="left-section">
        <div class="logo">
          <img src="@/assets/logo.png" alt="Profile" class="profile-img" />
        </div>
        <h1 class="page-heading">Shoulder Angel</h1>
        <p>
          Create your account or log in to access your Shoulder Angel for
          guidance anytime, anywhere.
        </p>
      </div>
      <div class="right-section">
        <h1 class="title page-heading">Sign Up</h1>
        <form @submit.prevent="handleSubmit" class="signup-form">
          <div class="fields">
            <div class="group-input">
              <label for="username">Username</label>
              <input
                type="text"
                id="username"
                class="form-control"
                placeholder="Username"
                v-model="username"
                @input="validateUsername"
                :class="{ 'is-invalid': usernameError }"
                required
              />
              <span v-if="usernameError" class="invalid-message">{{ usernameError }}</span>
            </div>
            <div class="group-input">
              <label for="email">Email Address</label>
              <input
                type="email"
                id="email"
                class="form-control"
                placeholder="Email Address"
                v-model="email"
                @input="validateEmail"
                :class="{ 'is-invalid': emailError }"
                required
              />
              <span v-if="emailError" class="invalid-message">{{ emailError }}</span>
            </div>

            <button type="submit" class="btn btn-dark mt-3">
              <span class="btn-text">Sign Up</span>
            </button>
          </div>
          <p class="join-us mt-3">
            Already have an account?
            <router-link
              to=""
              class="link"
              @touchend="navigateToLogin"
              @mousedown="navigateToLogin"
              >Sign In</router-link
            >
          </p>

          <!-- Success Dialog -->
          <DialogModal
            v-if="showSuccessDialog"
            :title="'Success'"
            :message="'Please check your mail to activate the account!'"
            :modalId="'successModal'"
            :button="'OK'"
            @close="handleDialogClose"
          />

          <!-- Error Dialog -->
          <DialogModal
            v-if="showErrorDialog"
            :title="'Error'"
            :message="'Something went wrong'"
            :modalId="'errorModal'"
            :button="'OK'"
            @close="showErrorDialog = false"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref } from "vue";
import { useRouter } from "vue-router";
import DialogModal from "./common/dialogOneBtn.vue"; // Import the reusable component

const username = ref("");
const email = ref("");
const usernameError = ref("");
const emailError = ref("");
const showSuccessDialog = ref(false); // Initialize as boolean for dialog control
const showErrorDialog = ref(false); // Initialize as boolean for dialog control
const router = useRouter();

const navigateToLogin = () => {
  setTimeout(() => {
    router.push("/login");
  }, 555);
};

const validateUsername = () => {
  // Add your username validation logic here
  usernameError.value = ""; // Clear error
};

const validateEmail = () => {
  // Add your email validation logic here
  emailError.value = ""; // Clear error
};

const handleSubmit = async () => {
  // Reset errors
  usernameError.value = "";
  emailError.value = "";

  try {
    // Define the API endpoint
    const url = `${process.env.VUE_APP_API_HOST}/api/website/send-otp`;

    // Construct the request payload
    const payload = {
      username: username.value,
      email_or_id: email.value,
    };

    // Make the API call with data in the request body
    const response = await axios.post(url, payload);

    console.log(response.data); // Handle response as needed
    showSuccessDialog.value = true; // Show success message or dialog
  } catch (error) {
    console.error("Error sending OTP:", error);
    showErrorDialog.value = true; // Show error message or dialog
  }
};

// Handle Dialog Close and Refresh the Page
const handleDialogClose = () => {
  showSuccessDialog.value = false; // Hide the dialog
  refresh(); // Refresh the page
};

// Function to refresh the page
function refresh() {
  window.location.reload();
}

function setHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener("resize", setHeight);
setHeight();
</script>

<style scoped lang="scss">
@import "../assets/style/main.scss";

.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $heading-color;
  background: $default-bg-color;
  min-height: calc(var(--vh, 1vh) * 100);
}

.signup-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  overflow: hidden;
  border-radius: 8px;
  background: $dark-color;
  box-shadow: 0 4px 12px $box-shadow-light-30;
  @media screen and (max-width: 576px) {
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .left-section,
  .right-section {
    flex: 1;
    gap: 20px;
    display: flex;
    padding: 60px 40px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .left-section {
    text-align: center;
    background-color: transparent;
    @media screen and (max-width: 576px) {
      gap: 15px;
      height: min-content;
    }

    .logo {
      img {
        width: 75px;
        object-fit: contain;
        @media screen and (max-width: 576px) {
          width: 55px;
        }
      }
    }

    p {
      color: $text-color;
      text-align: center;
    }
  }

  .right-section {
    background-color: $white-color;
    border-radius: 16px 0 0 16px;
    @media screen and (max-width: 576px) {
      height: 100%;
      border-radius: 16px 16px 0 0;
    }

    h1 {
      color: $text-color;
    }

    .signup-form {
      gap: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .fields {
        gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .group-input {
          gap: 15px;
          width: 100%;
          display: flex;
          text-align: left;
          position: relative;
          flex-direction: column;

          label {
            color: $text-color;
            display: block;
          }

          input {
            width: 100%;
            padding: 8px 16px;
            font-size: 16px;
            border-radius: 12px;
            border: 3px solid $box-shadow-dark-80;
          }

          .invalid-message {
            bottom: -2px;
            font-size: 12px;
            color: $red-color;
            position: absolute;
            transform: translateY(100%);
          }
        }
      }
    }
  }

  .join-us {
    color: $text-color;
    font-size: 1rem;

    .link {
      position: relative;
      color: $dark-color;
      text-decoration: none;
      font-weight: $semi_bold;
      &::after {
        content: "";
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        position: absolute;
        transform: scaleX(0);
        transform-origin: bottom right;
        background-color: $heading_color;
        transition: transform 0.2s ease-out;
      }

      &:hover::after,
      &.active::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    .left-section,
    .right-section {
      padding: 20px;
    }

    .right-section {
      h2 {
        font-size: 24px;
      }

      .signup-form {
        .form-group input {
          padding: 12px;
          font-size: 14px;
        }

        .signup-btn {
          padding: 12px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>