<template>
  <div>
    <button @click="loginWithFacebook" class="btn facebook-btn fb-login-btn">
      <img
        alt="Facebook Logo"
        src="https://img.icons8.com/color/24/000000/facebook-new.png"
      />
      <span class="btn-text">Login with Facebook</span>
    </button>
  </div>
</template>

<script>
import axios from "axios"; // Import axios for API requests

export default {
  name: "FacebookLogin",
  methods: {
    loginWithFacebook() {
      // Trigger Facebook login
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            // Fetch user profile information from Facebook
            this.getFacebookUserInfo();
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "public_profile,email" }
      );
    },
    getFacebookUserInfo() {
      // Fetch user data from Facebook Graph API
      window.FB.api("/me", { fields: "name,email,picture" }, (userInfo) => {
        const user = {
          name: userInfo.name,
          email: userInfo.email,
          facebookId: userInfo.id,
          picture: userInfo.picture.data.url,
        };

        // Save user info in localStorage
        localStorage.setItem("user", JSON.stringify(user));

        // Redirect to the home page
        this.$router.push("/chat");
        this.postUserDataToAPI(user);
      });
    },
    async postUserDataToAPI(user) {
      try {
        const apiUrl = `${process.env.VUE_APP_API_HOST}/api/website/facebook-login`;
        // Make a POST request to the backend API without waiting
        axios
          .post(apiUrl, {
            email_or_id: user.email,
            user_name: user.name,
            facebook_id: user.facebookId,
          })
          .then((response) => {
            console.log("Response:", response);
            if (response.status === 200) {
              if (response.data.refresh_token) {
                localStorage.setItem("token", response.data.refresh_token);
              }
            } else {
              console.log("API call failed", response);
            }
          })
          .catch((error) => {
            console.error("Error posting data to API", error);
          });
      } catch (error) {
        console.error("Error initiating API call", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/main.scss";

.btn {
  gap: 7px;
  width: 100%;
  display: flex;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  color: $heading-color;
  justify-content: center;
  border: 3px solid $box-shadow-light-80;

  &:hover,
  &:active,
  &:focus {
    color: $heading-color !important;
    background: transparent !important;
    border: 3px solid $box-shadow-light-80 !important;
  }

  img {
    width: 24px;
    object-fit: contain;
  }
}
</style>
