<template>
  <div class="new-chat-container">
    <!-- Main Content -->
    <main class="main-content">
      <!-- Top Navigation Bar -->
      <nav class="top-nav">
        <button
          class="btn toggle-sidebar"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebar"
          aria-controls="sidebar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#1A1D2E"
            class="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </button>
        <div class="d-flex gap-2 align-items-center">
          <template v-if="userData.picture == ''">
            <img :src="userData.picture" class="user-pic" />
          </template>
          <template v-else>
            <div class="user-initials">
              <span>{{ getInitials(userData.name) }}</span>
            </div>
          </template>
          <p class="user-name">{{ userData.name }}</p>
        </div>
      </nav>

      <div class="main-section gap-4">
        <!-- Welcome Section -->
        <template v-if="questions.length == 0">
          <div class="welcome-section">
            <img
              src="@/assets/logo.png"
              alt="Welcome Image"
              class="welcome-img"
            />
            <h1>Welcome</h1>
            <p class="welcome-text">
              I am here to listen, guide, and support you through scripture. How
              can we help you today?
            </p>
          </div>
        </template>
        <div class="chat-section" ref="chatSection">
          <div
            class="question-user"
            v-for="(item, index) in questions"
            :key="index"
          >
            <div class="d-flex question-set">
              <div class="user-initials">
                <span>{{ getInitials(userData.name) }}</span>
              </div>
              <p
                id="question"
                class="question"
                v-html="formatText(item.question)"
              ></p>
            </div>
            <div class="d-flex response-set">
              <img
                src="@/assets/logo.png"
                alt="Welcome Image"
                class="welcome-img"
              />
              <p id="response-{{ index }}" class="response">
                <span
                  v-for="(char, charIndex) in item.typedResponse"
                  :key="charIndex"
                  v-html="formatText(char)"
                ></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Chat Input -->
      <v-layout class="input-group row w-100 gap-3">
        <v-flex class="text-box">
          <textarea
            rows="1"
            ref="autoTextarea"
            v-model="newQuestion"
            placeholder="How can I help you?"
            style="height: 50px"
          ></textarea>
        </v-flex>
        <v-flex class="button-box">
          <button
            class="send-btn"
            @click="sendQuestion"
            :disabled="sendButtonDisabled"
          >
            <svg
              width="20"
              height="20"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-send-fill"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"
              />
            </svg>
          </button>
        </v-flex>
      </v-layout>
    </main>

    <!-- Sidebar -->
    <Sidebar></Sidebar>
  </div>
</template>

<script setup>
import axios from "axios"; // Import Axios for API requests
import { ref, reactive, onMounted, nextTick } from "vue";
import Sidebar from "./common/historySidebar.vue"; // Import the reusable component

// Reactive data
const questions = ref([]);
const newQuestion = ref("");
const chatSection = ref(null);
const autoTextarea = ref(null);
const isQuestionSent = ref(false);
const sendButtonDisabled = ref(false);

// Reactive userData object
const userData = reactive({
  name: "",
  picture: "",
});

// Fetch user data from localStorage on mount
onMounted(() => {
  const storedData = localStorage.getItem("user");
  if (storedData) {
    Object.assign(userData, JSON.parse(storedData));
  }
});

const fetchConversationMessages = async (conversationId) => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/api/website/converstion-list`,
      { params: { conversation_id: conversationId } }
    );

    // Initialize an array to store the structured messages
    const structuredMessages = [];
    let previousUserMessage = null;

    // Iterate over the messages
    response.data.messages.forEach((msg) => {
      // Check if the message is from the user
      if (msg.sender === "user" && msg.text.trim()) {
        // Store the user message
        previousUserMessage = {
          question: msg.text.trim(),
          response: "",
          typedResponse: [],
        };
      }
      // Check if the message is from the AI
      else if (msg.sender === "AI" && msg.text.trim() && previousUserMessage) {
        // If there's a previous user message, pair it with the AI response
        previousUserMessage.response = msg.text.trim();
        previousUserMessage.typedResponse = Array.from(msg.text.trim());

        // Push the complete message object to the structuredMessages array
        structuredMessages.push(previousUserMessage);

        // Reset previousUserMessage to null since it's already paired
        previousUserMessage = null;
      }
    });

    // Update the questions with structured messages
    questions.value = structuredMessages;

    // Scroll to the bottom after updating the messages
    nextTick(scrollToBottom);
  } catch (error) {
    console.error("Error fetching messages:", error);
  }
};

onMounted(() => {
  const conversationId = getConversationIdFromURL();
  if (conversationId) {
    fetchConversationMessages(conversationId);
  }
});

const typeWriterEffect = (responseText, index) => {
  questions.value[index].typedResponse = [];
  let i = 0;

  // Disable the send button when typing starts
  sendButtonDisabled.value = true;

  const interval = setInterval(() => {
    if (i < responseText.length) {
      questions.value[index].typedResponse.push(responseText[i]);
      i++;

      // Scroll to bottom on each character
      scrollToBottom();
    } else {
      clearInterval(interval);
      // Enable the send button once typing is done
      sendButtonDisabled.value = false;
      scrollToBottom(); // Ensure final scroll
    }
  }, 17);
};

const sendQuestion = async () => {
  if (newQuestion.value.trim() !== "") {
    const questionObj = {
      question: newQuestion.value,
      response: "Typing the response...", // Placeholder
      typedResponse: [],
    };
    let email = "";

    // Retrieve user info from localStorage
    const storedUserInfo = localStorage.getItem("user");
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      email = userInfo.email;
      console.log("User Email:", email);
    }

    // Add question to UI and adjust layout
    questions.value.push(questionObj);

    // Set isQuestionSent to true to hide the welcome section
    isQuestionSent.value = true;
    typeWriterEffect(questionObj.response, questions.value.length - 1);

    try {
      const baseURL = `${process.env.VUE_APP_API_HOST}/api/website/conversation/`;
      let conversationId = getConversationIdFromURL();

      // Prepare the request payload (single hit)
      const userPayload = {
        email_or_id: email,
        text: newQuestion.value,
        sender: "user",
        conversation_id: conversationId || "", // Send empty if no conversation ID
      };

      // Send the question and get the response
      const response = await axios.post(baseURL, userPayload);

      // Get the conversation ID from the response (if it's a new one)
      conversationId = response.data.conversation_id;
      if (!getConversationIdFromURL()) {
        updateURLWithConversationId(conversationId); // Update the URL if needed
      }

      // Call your updated API here
      const aiResponses = await axios.post(
        `${process.env.VUE_APP_GPT_HOST}/generate_response`,
        {
          user_input: newQuestion.value,
          conversation_history: questions.value.map((q) => ({
            user: q.question,
            ai: q.response,
          })),
        }
      );

      const apiResponse = aiResponses.data;

      // Save the AI response in the conversation
      const aiPayload = {
        email_or_id: email,
        text: apiResponse.response,
        sender: "AI",
        conversation_id: conversationId,
      };
      await axios.post(baseURL, aiPayload);
      // Update UI with the AI response
      questions.value[questions.value.length - 1].response =
        apiResponse.response; // API response message
      questions.value[questions.value.length - 1].typedResponse = []; // Reset typed response for the new answer
      typeWriterEffect(apiResponse.response, questions.value.length - 1);
    } catch (error) {
      console.error("Error sending question:", error);

      // Display error message in UI
      questions.value[questions.value.length - 1].response =
        "Error retrieving response. Please try again.";
      typeWriterEffect(
        questions.value[questions.value.length - 1].response,
        questions.value.length - 1
      );
    }

    resetTextareaHeight();
    newQuestion.value = ""; // Clear the input
    autoTextarea.value.style.height = "auto"; // Reset height immediately
    adjustHeight({ target: autoTextarea.value }); // Adjust height after reset
    resetTextareaHeight();
  }
};

// Function to get user initials (first two letters)
const getInitials = (name) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  const initials = nameParts
    .map((part) => part.charAt(0).toUpperCase())
    .join("");
  return initials.slice(0, 2); // Get only the first two initials
};

// Helper function to extract conversation_id from the URL
const getConversationIdFromURL = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("conversation_id");
};

// Helper function to update the URL with conversation_id
const updateURLWithConversationId = (conversationId) => {
  const newURL = `${window.location.origin}${window.location.pathname}?conversation_id=${conversationId}`;
  window.history.pushState({}, "", newURL);
};

// Function to reset the textarea height
const resetTextareaHeight = () => {
  if (autoTextarea.value) {
    autoTextarea.value.style.height = "50px"; // Set the height to default (50px)
  }

  // Scroll to the bottom after adding the new question
  scrollToBottom();
};

// Adjust textarea height
const adjustHeight = (event) => {
  const textarea = event.target;
  textarea.style.height = "auto";
  const lineHeight = parseFloat(window.getComputedStyle(textarea).lineHeight);
  const maxHeight = lineHeight * 4;
  if (textarea.scrollHeight > maxHeight) {
    textarea.style.height = `${maxHeight}px`;
    textarea.style.overflowY = "scroll";
  } else {
    textarea.style.height = `${textarea.scrollHeight}px`;
    textarea.style.overflowY = "hidden";
  }
};

// Scroll to bottom function
const scrollToBottom = () => {
  nextTick(() => {
    if (chatSection.value) {
      chatSection.value.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  });
};

// Format text by replacing new lines with <br>
const formatText = (text) => text.replace(/\n/g, "<br>");

// Event listener for adjusting textarea height
onMounted(() => {
  if (autoTextarea.value) {
    autoTextarea.value.addEventListener("input", adjustHeight);
  }
});
function setHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

window.addEventListener("resize", setHeight);
setHeight();
</script>

<style scoped lang="scss">
@import "../assets/style/main.scss";

.new-chat-container {
  display: flex;
  height: calc(var(--vh, 1vh) * 100);

  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $dark-color;

    .top-nav {
      display: flex;
      padding: 20px 5%;
      background-color: $dark-color;
      justify-content: space-between;

      @media screen and (max-width: 576px) {
        padding: 15px;
      }

      button {
        &.toggle-sidebar {
          border: none;
          transition: all 0.4s;
        }
      }

      .user-initials {
        width: 34px;
        height: 34px;
        padding: 8px;
        display: flex;
        font-size: 16px;
        color: $dark-color;
        border-radius: 50%;
        align-items: center;
        line-height: normal;
        font-weight: $medium;
        background: $black-color;
        justify-content: center;
      }

      .user-name {
        font-size: 17px;
        color: $heading-color;
        font-weight: $medium;
        overflow: hidden;
        display: -webkit-box; /* Needed for webkit-based browsers */
        -webkit-line-clamp: 2; /* Limits text to 4 lines */
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;

        @media screen and (max-width: 576px) {
          max-width: 188px;
        }
      }
    }

    .main-section {
      display: flex;
      overflow-y: auto;
      max-width: 100vw;
      padding: 20px 40px;
      flex-direction: column;
      height: calc(100% - 176px);
      @media screen and (max-width: 576px) {
        padding: 20px;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 7px;
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $white-color;
        border-radius: 7px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $light-color;
      }

      .welcome-section {
        min-height: 300px;
        flex-grow: 1;
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .welcome-img {
          width: 75px;
          margin-bottom: 20px;
        }

        h1 {
          margin-bottom: 20px;
        }

        .welcome-text {
          max-width: 600px;
          color: $text-color;
          text-align: center;
        }
      }

      .chat-section {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .question-user {
          gap: 25px;
          display: flex;
          padding-inline: 22px;
          flex-direction: column;
          @media screen and (max-width: 576px) {
            padding-inline: 0;
          }

          .question {
            max-width: 66vw;
            text-align: left;
            padding: 15px 30px;
            width: max-content;
            position: relative;
            color: $dark-color;
            overflow-wrap: anywhere;
            background: $heading-color;
            border-radius: 24px 24px 0 24px;

            @media screen and (max-width: 576px) {
              max-width: 70vw;
            }
          }

          .response {
            width: auto;
            max-width: 77vw;
            text-align: left;
            overflow: hidden;
            font-weight: $medium;
            position: relative;
            padding: 15px 30px;
            color: $text-color;
            align-self: flex-start;
            overflow-wrap: anywhere;
            background: $light-color;
            border-radius: 24px 24px 24px 0;
          }
        }

        .question-set {
          gap: 12px;
          align-self: flex-end;
          align-items: flex-end;
          flex-direction: row-reverse;

          .user-initials {
            width: 34px;
            height: 34px;
            padding: 8px;
            display: flex;
            font-size: 16px;
            color: $dark-color;
            border-radius: 50%;
            align-items: center;
            line-height: normal;
            font-weight: $medium;
            background: $black-color;
            justify-content: center;
          }
        }

        .response-set {
          gap: 12px;
          align-items: flex-end;

          img {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            object-fit: contain;
          }
        }

        &.trail-version {
          .response {
            &::after {
              content: "";
              width: 100%;
              height: calc(100% - 60px);
              bottom: 0;
              left: 0;
              z-index: 9999;
              position: absolute;
              backdrop-filter: blur(3px) saturate(177%);
              -webkit-backdrop-filter: blur(3px) saturate(177%);
              background-color: $box-shadow-dark-80;
              -webkit-box-shadow: 2px -10px 17px 0px $box-shadow-dark-80;
              -moz-box-shadow: 2px -10px 17px 0px $box-shadow-dark-80;
              box-shadow: 2px -10px 17px 0px $box-shadow-dark-80;
            }
          }
        }
      }
    }

    .input-group {
      display: flex;
      --bs-gutter-x: 0;
      padding: 20px 6%;
      align-items: center;
      justify-content: center;
      background-color: $dark-color;
      @media screen and (max-width: 576px) {
        padding: 20px;
      }

      .text-box {
        width: calc(100% - 48px - 16px);
      }

      .button-box {
        width: auto;
      }

      textarea {
        width: 100%;
        resize: none; /* Prevent manual resizing by user */
        max-height: 6em; /* Max height for 4 lines (1.5em per line is a typical size) */
        font-size: 16px;
        overflow-y: auto; /* Adds scroll when max height is reached */
        line-height: 1.5em;
        padding-block: 12px;
        box-sizing: border-box;
        background: $white-color;
        border: 2px solid $light-color;

        /* width */
        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: none;
          border-radius: 7px;
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 7px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $black-color;
        }
      }

      .send-btn {
        width: 48px;
        height: 48px;
        border: none;
        display: flex;
        margin-bottom: 8px;
        color: $dark-color;
        border-radius: 50%;
        align-items: center;
        transform: all 0.4s;
        justify-content: center;
        background-color: $black-color;

        svg {
          transform: translate(-1px, 1px);
        }

        &:disabled {
          background: $disabled-color;
        }

        &:active {
          transform: scale(0.92);
          background: $heading-color;
        }
      }
    }
  }
}
</style>
