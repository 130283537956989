/* global FB */
import App from './App.vue'
import router from './router';
import { createApp } from 'vue'
import GoogleLogin from 'vue3-google-login';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Import Bootstrap JS (Optional)
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const app = createApp(App);

// Load Facebook SDK
const loadFacebookSDK = () => {
  window.fbAsyncInit = function () {
    FB.init({
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      xfbml: true,
      version: 'v21.0'
    });
  };

  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
};

// Call the function to load the SDK
loadFacebookSDK();

app.use(GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});

app.use(router); // Use the router
app.mount('#app');

const paypalScript = document.createElement( "script" );
paypalScript.id = "paypal-sdk";
paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
paypalScript.setAttribute("data-sdk-integration-source", "button-factory");
document.head.appendChild(paypalScript);